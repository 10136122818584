export const config = {
    RPC_URL: 'https://rpc.flixnet-4.omniflix.network',
    REST_URL: 'https://rest.flixnet-4.omniflix.network',
    EXPLORER_URL: 'https://explorer.omniflix.network',
    STAKING_URL: 'https://flix.omniflix.co/stake',
    NETWORK_NAME: 'OmniFlix',
    NETWORK_TYPE: 'testnet',
    CHAIN_ID: 'flixnet-4',
    CHAIN_NAME: 'OmniFlix FlixNet-4',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    COIN_TYPE: 118,
    COSMOSTAION: null,
    COINGECKO_ID: '-',
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
